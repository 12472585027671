import React, { useState, useEffect } from "react"
import { Form, Row, Col, Button } from "react-bootstrap"
import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import moment from "moment";
import axios from "axios";
import * as qs from "query-string";
import $ from 'jquery/dist/jquery.min.js'
import FileField from "./elements/file";
import _ from "lodash";



function MySimpleForm(props) {
    const [validated, setValidated] = useState(false);
    const [showerror, setShowerror] = useState(false);
    const [showthankyou, setThankyou] = useState(false);
    const [stepPassed, setStepsPassed] = useState(0);
    const [formvalues, setFormvalues] = useState("");
    const [token, setToken] = useState("");
    const myRef = React.createRef();
    const recaptchaRef = React.createRef();
    const [countryCode, setCountryCode] = useState('');
    const [showCapcha, setShowCaptch] = useState(true);
    const [file, setFile] = useState("");
    const [step, setStep] = useState(1);

    const to_email_id = "Samantha.hossack@tlclondon.com,jamie@tlclondon.com";

    useEffect(() => {
        axios.get('https://extreme-ip-lookup.com/json/').then(response => {
        //console.log("countryCode", response.data.countryCode);
            setCountryCode(response.data.countryCode)
        }).catch(err =>
            console.log(err)
        );
        let el = document.querySelectorAll('input[name="position"]');
        if(el.length > 0) {
            el[0].value = props.title;
        }
    },[]);

    let fields = ([
        {
            element: "config",
            formname: "CAREER APPLY NOW",
            form_type: "contact",
            error_text: "Highlighted fields are required | invalid",
            success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
            email_temp_user: "career_user",
            email_temp_admin: "career_admin",
            email_subject_user: "Career Opportunity",
            email_subject_admin: "Career Opportunity",
            email_server_func: "contact",
            event_tracking: "contact",
            page_url: "/about/careers/"
        },
        {
            text: '<h3 class="text-center">Apply to Join our Team</h3>',
            element: "html",
            class: "",
            step: [1]
        },
        {
            text: '<p class="text-center mt-2 mb-5">This exercise is your chance to tell us more about yourself so that we can get an idea of your background, hobbies and interests in order to see how well you would suit our business and where you might work best, please take your time.</p>',
            element: "html",
            class: "",
            step: [1]
        },
        {
            grpmd: "6",
            label: "First name",
            placeholder: "First name",
            name: "first_name",
            type: "text",
            element: "input",
            required: true,
            patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
            labelClass: "content_b-18",
            step: [1]
        },
        {
            grpmd: "6",
            label: "Last name",
            placeholder: "Last name",
            name: "last_name",
            type: "text",
            element: "input",
            required: true,
            patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
            labelClass: "content_b-18",
            step: [1]
        },
        {
            grpmd: "6",
            label: "Email Address",
            placeholder: "Email Address",
            name: "email",
            type: "email",
            element: "input",
            required: true,
            patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
            labelClass: "content_b-18",
            step: [1]
        },
        {
            grpmd: "6",
            label: "Telephone Number",
            placeholder: "Phone number",
            name: "telephone",
            type: "text",
            element: "input",
            required: true,
            patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
            labelClass: "content_b-18",
            step: [1]
        },
        {
            grpmd: "12",
            label: "Position applying for",
            placeholder: "Position",
            name: "position",
            type: "text",
            element: "input",
            // value: props.title,
            required: true,
            // patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
            labelClass: "content_b-18",
            step: [1]
        },
        {
            grpmd: "12",
            label: "Upload your CV (PDF/doc)",
            placeholder: "Click to attach your CV *",
            name: "file",
            type: "file",
            element: "file",
            id: props.form_key,
            required: true,
            accept: ".pdf,.doc",
            fieldClass: "form-control d-flex flex-column justify-content-center form-control-browsefile custom custom-file",
            labelClass: "content_b-18",
            step: [1]
        },
        {
            grpmd: "12",
            label: "Tell us more about yourself? Please be as detailed as you like as this will give us a good idea of who you are (include any experiences you think relevant",
            placeholder: "",
            name: "intro",
            element: "textarea",
            class: "mb-0 pt-2",
            rows:"5",
            required: true,
            labelClass: "content_b-18",
            step: [2]
        },
        {
            grpmd: "12",
            label: "Tell us why you'd be an asset to our business and a valued member of a committed team?",
            placeholder: "",
            name: "asset",
            element: "textarea",
            class: "mb-0 pt-2",
            rows:"5",
            required: true,
            labelClass: "content_b-18",
            step: [2]
        },
        {
            grpmd: "12",
            label: "What are your hobbies and interests outside of the work environment and how have they taught you something about yourself",
            placeholder: "",
            name: "hobbies",
            element: "textarea",
            class: "mb-0 pt-2",
            rows:"5",
            required: true,
            labelClass: "content_b-18",
            step: [2]
        },
        {
            grpmd: "12",
            label: "Where do you feel your strengths really lie and how do you know?",
            placeholder: "",
            name: "strengths",
            element: "textarea",
            class: "mb-0 pt-2",
            rows:"5",
            required: true,
            labelClass: "content_b-18",
            step: [3]
        },
        {
            grpmd: "12",
            label: "Give us an example of when you've handled a difficult situation successfully in your life and what did you learn about yourself?",
            placeholder: "",
            name: "situation",
            element: "textarea",
            class: "mb-0 pt-2",
            rows:"5",
            required: true,
            labelClass: "content_b-18",
            step: [3]
        },
        {
            grpmd: "12",
            label: "Give us an example of when you've handled a situation with motivation and determination?",
            placeholder: "",
            name: "motivation",
            element: "textarea",
            class: "mb-0 pt-2",
            rows:"5",
            required: true,
            labelClass: "content_b-18",
            step: [3]
        },
        {
            grpmd: "12",
            label: "Tell us about an experience where you have shown responsibility and how you handled that?",
            placeholder: "",
            name: "responsibility",
            element: "textarea",
            class: "mb-0 pt-2",
            rows:"5",
            required: true,
            labelClass: "content_b-18",
            step: [4]
        },
        {
            grpmd: "12",
            label: "What is success to you? Please do provide an example if you feel appropriate",
            placeholder: "",
            name: "success",
            element: "textarea",
            class: "mb-0 pt-2",
            rows:"5",
            required: true,
            labelClass: "content_b-18",
            step: [4]
        },
        {
            grpmd: "12",
            label: "What is failure to you? Please do provide an example if you feel appropriate.",
            placeholder: "",
            name: "failure",
            element: "textarea",
            class: "mb-0 pt-2",
            rows:"5",
            required: true,
            labelClass: "content_b-18",
            step: [4]
        },
        {
            grpmd: "12",
            label: "Please tell us anything else you feel is important in order that we invite you in for interview as a really great candidate?",
            placeholder: "",
            name: "message",
            element: "textarea",
            class: "mb-0 pt-2",
            rows:"5",
            required: true,
            labelClass: "content_b-18",
            step: [5]
        },
        {
            grpmd: "12",
            label: "Cover letter",
            placeholder: "Write your letter here",
            name: "cover_letter",
            element: "textarea",
            class: "mb-0 pt-2",
            rows:"5",
            required: true,
            labelClass: "content_b-18",
            step: [5]
        },
        {
            grpmd: "12",
            name: "SUBMIT",
            type:"submit",
            element: "button",
            value: "Submit",
            class: "btn subscribe-btn btn btn-primary w-100 mb-4",
            labelClass: "content_b-18",
            step: [5]
        },
        {
            text: 'By clicking Submit, you agree to our <a href="/terms-and-conditions/">Terms &amp; Conditions</a> and <a href="/privacy-policy/">Privacy Policy.</a>',
            element: "html",
            class: "form-txt content_r-m",
            step: [5]
        },
        {
            element: "captcha",
            class: "py-2",
            captchaRef: recaptchaRef
        },
    ]);

    const handlechange = event => {
        // remove initial empty spaces
        event.target.value = event.target.value.trimStart()
    }

    useEffect(() => {
        if (token !== '') {
            const processFromData = async () => {
                let formData = new FormData();
                
                if (formvalues?.files) {
                    formData.append('files.attachment', formvalues.files, formvalues.files.name); // appending file
                }

                formData.append('data', JSON.stringify(formvalues));

                postFormData(formData).then(async apiRes => {
                    // lets send mail
                    if (apiRes?.attachment.length > 0) {
                        // If image from externall url, use that
                        if (apiRes.attachment[0].url.match(/(http|https):\/\//g)) {
                            formvalues['file'] = `${apiRes.attachment[0].url}`;
                            formvalues['cvname'] = `${apiRes.attachment[0].url}`;
                        } else { // or we consider it is loading from api url
                            formvalues['file'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment[0].url}`;
                            formvalues['cvname'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment[0].url}`;
                        }

                        console.log(formvalues['cvname'])
                    }
                    await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
                        method: `POST`,
                        mode: "no-cors",
                        headers: {
                          'Access-Control-Allow-Origin': '*',
                          "Content-Type": "application/x-www-form-urlencoded",
                        },
                        body: qs.stringify(formvalues),
                    });
                    props.closePopup()
                });

                formvalues['g-recaptcha-response'] = token;

                // tracking event
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'formSubmit',
                    'formType': 'form-'+fields[0].event_tracking,
                    'formId': 'form-'+fields[0].event_tracking,
                    'formName': fields[0].formname,
                    'formLabel': fields[0].formname
                });

                setShowerror(false);
                setThankyou(true);
                setShowCaptch(false);
                setTimeout(() => {
                    setThankyou(false);
                    setShowCaptch(true);
                }, 3000);
                myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })

            }
            processFromData();
        }
    }, [token]);

    const handleonVerify = token => {
        setToken(token);
    };

    const handleSubmit = event => {
        if(step == 5) {
            event.preventDefault();
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                setShowerror(true);
                setValidated(true);
                setThankyou(false);
                myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
            } else {
                const formsdata = (event.target);
                const json = {};
                Object.keys(formsdata).map(key => (
                    json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
                ))
                json['email_temp_user'] = fields[0].email_temp_user;
                json['email_temp_admin'] = fields[0].email_temp_admin;
                json['formname'] = fields[0].formname;
                json['g-recaptcha-response'] = token;
    
                json['email_subject_user'] = fields[0].email_subject_user;
                json['email_subject_admin'] = fields[0].email_subject_admin;
    
                json["name"] = json["first_name"] + " " + json["last_name"];
    
                json['files'] = file;
                setFile(file);
    
                if(props.title) {
                    json["job_role"] = props.title;
                }
    
                json['extra'] = _.omit(json, ["formname", "name", "email", "telephone"])
    
                setFormvalues(json);
                recaptchaRef.current.execute();
                setValidated(false);
    
                // reset form
                const form = event.target
                form.reset();
                
            }
        } else {
            validateSteps(event)
        }
    };

    const handleAttachment = event => {
        const fileItem = event.target.files[0]; // accesing file
        var d = document.getElementById(props.form_key);
        //alert(d.value);
        $(`#career-form .form-control-browsefile .custom-file-label`).html(d.value.replace(/C:\\fakepath\\/, ''));
     
        if ((fileItem == '') || (fileItem == null) || (fileItem == undefined)) {
            $(`#career-form .form-control-browsefile`).addClass('fileerror');
            $(` #career-form .form-control-browsefile`).removeClass('fileadded');
        } else {
            $(` #career-form .form-control-browsefile`).removeClass('fileerror');
            $(` #career-form .form-control-browsefile`).addClass('fileadded');
        }
    
        setFile(fileItem); // storing file 
    };

    const validateSteps = (event) => {
        setShowerror(false);
        setValidated(false);
        event.preventDefault();
        const form = event.currentTarget;
        if(step == 1) {
            if ((file == '') || (file == null)) {
                $(` #career-form .form-control-browsefile`).addClass('fileerror');
            } else {
                $(` #career-form .form-control-browsefile`).removeClass('fileerror');
            }
        }
        if (form.checkValidity() === false) {
            setShowerror(true);
            setValidated(true);
            setThankyou(false);
            myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        } else {
            if(stepPassed <= step) {
                setStepsPassed(stepPassed+1)
            }
            setStep(step+1);
        }
    }
 
    return (
        <div className="form-wrapper">
            <div ref={myRef} />

            {showerror && <div className="alert alert-danger mt-3 text-center">
                <p className="mb-0">{fields[0].error_text}</p>
            </div>}

            {showthankyou && <div className="alert alert-success mt-3 text-center">
                <p className="mb-0">{fields[0].success_text}</p>
            </div>}

            <Form name={fields[0].formname} id={`career-form`} className="mb-0 pb-0 border-0" noValidate validated={validated} onSubmit={handleSubmit}>
                <input type="hidden" name="form_name" value={fields[0].formname} />
                <input type="hidden" name="form_type" value={fields[0].form_type} />
                <input type="hidden" name="to_email_id" value={to_email_id} />
                <input type="hidden" name="bot-field" />
                <Row>
                    {fields.map((field, index) => {
                        if ("input" === field.element ) {
                            return (
                                <React.Fragment>
                                    {(field.step[0] <= step || field.step[0] <= stepPassed) && <InputField
                                        name={field.name}
                                        grpmd={field.grpmd}
                                        ref={field.ref}
                                        type={(field.type === "date" || field.type === "time") ? "text" : field.type}
                                        fieldClass={field.class}
                                        label={field.label}
                                        labelClass={field.labelClass}
                                        placeholder={field.placeholder}
                                        required={field.required}
                                        key={`${field.element}~${index}`}
                                        value={field.value}
                                        pattern={field.patternchk}
                                        handlechange={handlechange}
                                        min={field.type == "date" ? moment().format("YYYY-MM-DD") : ""}
                                        onFocus={(e) => {
                                            if(field.type === "date" || field.type === "time") {
                                                e.target.type = field.type
                                            }
                                        }}
                                        mainClass={field.step.includes(step) ? "": "d-none"}
                                        defaultValue={field.defaultValue}
                                    />}
                                </React.Fragment>
                            );
                        }
                        if ("file" === field.element) {
                            return (
                                <React.Fragment>
                                    {(field.step[0] <= step || field.step[0] <= stepPassed) && <FileField
                                        name={field.name}
                                        grpmd={field.grpmd}
                                        ref={field.ref}
                                        label={field.label}
                                        type={field.type}
                                        fieldClass={field.fieldClass}
                                        labelClass={field.labelClass}
                                        placeholder={field.placeholder}
                                        key={`${field.element}~${index}`}
                                        accept={field.accept}
                                        handlechange={handleAttachment}
                                        required={field.required}
                                        file={file}
                                        id={field.id}
                                        files={[]}
                                        mainClass={field.step.includes(step) ? "": "d-none"}
                                    />}
                                </React.Fragment>
                            );
                        }
                        if ("select" === field.element) {
                            return (
                                <React.Fragment>
                                    {(field.step[0] <= step || field.step[0] <= stepPassed) && <SelectField
                                        name={field.name}
                                        grpmd={field.grpmd}
                                        label={field.label}
                                        ref={field.ref}
                                        required={field.required}
                                        fieldClass={field.class}
                                        placeholder={field.placeholder}
                                        values={field.values}
                                        key={`${field.element}~${index}`}
                                        handlechange={handlechange}
                                        componentprops={props}
                                        mainClass={field.step.includes(step) ? "": "d-none"}
                                    />}
                                </React.Fragment>
                            );
                        }
                        if ("textarea" === field.element) {
                            return (
                                <React.Fragment>
                                    {<TextAreaField
                                        name={field.name}
                                        grpmd={field.grpmd}
                                        label={field.label}
                                        ref={field.ref}
                                        rows={field.rows}
                                        fieldClass={field.class}
                                        labelClass={field.labelClass}
                                        placeholder={field.placeholder}
                                        required={(field.step[0] <= step || field.step[0] <= stepPassed) ? field.required : false}
                                        key={`${field.element}~${index}`}
                                        handlechange={handlechange}
                                        mainClass={field.step.includes(step) ? "": "d-none"}
                                    />}
                                </React.Fragment>
                            );
                        }
                        if ("checkbox" === field.element) {
                            return (
                                <React.Fragment>
                                    {(field.step[0] <= step || field.step[0] <= stepPassed) && <CheckboxField
                                        name={field.name}
                                        ref={field.ref}
                                        value={field.value}
                                        fieldClass={field.class}
                                        placeholder={field.placeholder}
                                        required={field.required}
                                        key={`${field.name}~${index}`}
                                        handlechange={handlechange}
                                        mainClass={field.step.includes(step) ? "": "d-none"}
                                    />}
                                </React.Fragment>
                            );
                        }
                        if ("html" === field.element) {
                            return (
                                <React.Fragment>
                                    {(field.step[0] <= step || field.step[0] <= stepPassed) && <HtmlBox
                                        text={field.text}
                                        fieldClass={field.class}
                                        key={`${field.element}~${index}`}
                                        mainClass={field.step.includes(step) ? "": "d-none"}
                                    />}
                                </React.Fragment>
                            );
                        }
                        if ("captcha" === field.element && showCapcha) {
                            return (
                                <ReCaptchaBox
                                    fieldClass={field.class}
                                    captRef={field.captchaRef}
                                    key={`${field.element}~${index}`}
                                    handleonVerify={handleonVerify}
                                />
                            );
                        }
                        if ("button" === field.element) {
                            return (
                                <>
                                    <Row className="justify-content-between w-100 mb-4 mx-0">
                                        {step > 1 && <Col className="text-start px-o">
                                            <Button onClick={() => {
                                                setStep(step-1);
                                                setStepsPassed(step-1);
                                            }}>Previous</Button>
                                        </Col>}
                                        {step < 5 && <Col className="text-end px-0">
                                            <Button type="submit" >Next</Button>
                                        </Col>}
                                    </Row>
                                    {step == 5 && <ButtonField
                                        name={field.name}
                                        fieldClass={field.class}
                                        type={field.type}
                                        value={field.value}
                                        key={`${field.element}~${index}`}
                                        grpmd={field.grpmd}
                                        // extraClass="contact-btn btn"
                                    />}
                                </>
                            );
                        }
                    })
                }
                </Row>
            </Form>
        </div>
    );
}


const CareerFormPage = (props) => (
    <MySimpleForm {...props}/>
)

export default CareerFormPage
