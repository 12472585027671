import { graphql } from "gatsby";
import React from "react";
import CareerDetail from "../components/CareerDetail/CareerDetail";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Seo from "../components/seo";

const CareerTemplate = (props) => {
    const GQLPage = props.data.glstrapi?.career;
    return (
        <React.Fragment>
            <Seo
                title={`${GQLPage.Title} | Estate agent jobs in Chelsea, Earls Court and Kensington`}
                description={`Considering taking on a new challenge? We are currently recruiting for ${GQLPage.Title}, so why not tell us a bit more about yourself?`}
                location={props.location}
            />
            <Header menuLinks={[]}/>
            <CareerDetail
                data={GQLPage}
            />
            <Footer
                popularSearch="Popular_About"  showBreadcrumb = {true}
            />
        </React.Fragment>
    )
}

export default CareerTemplate;

export const pageQuery = graphql`
    query careerDetail($id: ID!) {
        glstrapi {
            career(id: $id, publicationState: LIVE) {
                _id
                Title
                Description
                Url
                Video_Url
                Meta_Title
                Meta_Description
                Content
                Alias
                Sort
                Publish
                Tile_Image {
                    url
                    alternativeText
                }
            }
            globalModule {
                Book_A_Appointment {
                    _id
                    Book_Title
                    Description
                    Cta_Label
                    Cta_Link {
                        _id
                        URL
                    }
                }
                Lets_Work_Together {
                    _id
                    Title
                    Sm_Title
                    Cta_1_Label
                    Cta_2_Label
                    Cta_1_Link {
                        URL
                        _id
                    }
                    Cta_2_Link {
                        URL
                        _id
                    }
                }
            }
        }
    }
`

