import React, { useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col, Breadcrumb, Modal } from "react-bootstrap";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import HTMLReactParser from "html-react-parser";
import "./CareerDetail.scss";
import CareerFormPage from "../forms/career-form";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from "react-share";
import PlayVideo from "../PlayVideo/PlayVideo";

const CareerDetail = (props) => {
    const [isPlaying, setPlaying] = useState(false);
    const [show, setShow] = React.useState(false);

    let processedImages = JSON.stringify({});
    if (props?.data?.imagetransforms?.Tile_Image_Transforms) {
        processedImages = props?.data?.imagetransforms?.Tile_Image_Transforms;
    }
    const shareurl = typeof window !== "undefined" ? window.location.href : "";

    const [shareIcons, setShareicons] = React.useState(false);

    const openShareicons = () => {
        setShareicons(true)
        if (shareIcons === true) {
            setShareicons(false)
        }
    }
    const trackerShare = event => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: "Share - social icons",
            formType: event + " - " + shareurl,
            formId: "Share",
            formName: "Share",
            formLabel: "Share",
        })
    }
    return (
        <React.Fragment>
            <section className="career-bio">
                <Container>
                    <Row>
                        <Col md={12} className="bio-wrapper">
                            <div className="bio-img">
                                <ImageTransform
                                    imagesources={props?.data?.Tile_Image?.url}
                                    renderer="pic-src" imagename="careers.Tile_Image.banner"
                                    attr={{ alt: props?.data?.Tile_Image?.alternativeText, className: 'm-0' }}
                                    imagetransformresult={processedImages}
                                    id={props?.data?._id}
                                />
                                {(props?.data?.Video_Url && !isPlaying) &&
                                    <React.Fragment>
                                        <i
                                            className="video-icon"
                                            onClick={async () => {
                                                setPlaying(true);
                                            }}
                                        />
                                    </React.Fragment>
                                }
                                {(props?.data?.Video_Url && isPlaying) && <div className="inline-video inline-video-container">
                                    {isPlaying &&
                                        <React.Fragment>
                                            <div className="d-block">
                                                <PlayVideo
                                                    isOpen={isPlaying}
                                                    videoId={props?.data?.Video_Url}
                                                    stopPlay={setPlaying}
                                                    width={'100%'} height={'100%'}
                                                    fullscreen={true}
                                                />
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>}
                            </div>

                            <div className="bio-details-wrapper ">
                                <div className="bio-details">
                                    <h1>{props?.data?.Title}</h1>
                                    <div className="contact-info">
                                        <button className="btn d-none d-md-block" onClick={() => setShow(true)}>
                                            Apply for this job
                                        </button>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <a href="javascript:;" className="share cursor-pointer d-flex" onClick={openShareicons}>
                                            <i className="icon-share" />
                                            Share this job
                                        </a>
                                        <span className="ps-4">
                                            {shareIcons && (
                                                <div onClick={openShareicons} className="property-share-social-icons2 mobile-details-socila-share">
                                                    <FacebookShareButton
                                                        onClick={() =>
                                                            trackerShare("FacebookShareButton")
                                                        }
                                                        url={shareurl}
                                                        className="my-share-button facebook-share mx-1"
                                                    >
                                                        <FacebookIcon
                                                            size={32}
                                                            round={false}
                                                            borderRadius={`10`}
                                                        />
                                                    </FacebookShareButton>
                                                    <TwitterShareButton
                                                        onClick={() =>
                                                            trackerShare("TwitterShareButton")
                                                        }
                                                        url={shareurl}
                                                        className="my-share-button twitter-share mx-1"
                                                    >
                                                        <TwitterIcon
                                                            size={32}
                                                            round={false}
                                                            borderRadius={`10`}
                                                        />
                                                    </TwitterShareButton>
                                                    <LinkedinShareButton
                                                        onClick={() =>
                                                            trackerShare("LinkedinShareButton")
                                                        }
                                                        url={shareurl}
                                                        className="my-share-button linkedin-share mx-1"
                                                    >
                                                        <LinkedinIcon
                                                            size={32}
                                                            round={false}
                                                            borderRadius={`10`}
                                                        />
                                                    </LinkedinShareButton>
                                                    <WhatsappShareButton
                                                        onClick={() =>
                                                            trackerShare("WhatsappShareButton")
                                                        }
                                                        url={shareurl}
                                                        className="my-share-button whatsapp-share mx-1"
                                                    >
                                                        <WhatsappIcon
                                                            size={32}
                                                            round={false}
                                                            borderRadius={`10`}
                                                        />
                                                    </WhatsappShareButton>
                                                </div>
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className="top-content">
                                    <div className="w-fit-content">
                                        <button className="btn mobile desktop" onClick={() => setShow(true)}>
                                            Apply for this job
                                        </button>
                                    </div>
                                    {props?.data?.Content !== null && <div className="content-wrapper">
                                        {HTMLReactParser(props?.data?.Content)}
                                    </div>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Modal className="form-popup" onHide={() => setShow(false)} show={show}>
                <button onClick={() => setShow(false)} type="button" className="close-modal">
                    <i className="icon-close-modal"></i>
                </button>
                <div className="form-block career-form">
                    <CareerFormPage
                        form_key="career-upload-file"
                        title={props?.data?.Title}
                        closePopup={() => setShow(false)}
                    />
                </div>
            </Modal>
        </React.Fragment>
    )
}
export default CareerDetail;
